import React, { ChangeEvent, useCallback } from 'react';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as ResetIcon } from '../../assets/icons/reset.svg';
import { getContrastYIQ } from '../../colors.utils';
import { useGoTo } from '../../hooks/useGoTo';
import {
    decrementTagInTags,
    incrementTagInTags,
    removeTagInTags,
    resetHistoryInTags,
    updateTag,
} from '../../tag.utils';
import { blurOnEnter } from '../../utils';
import { useTags } from '../TagContext';
import { TagGraph } from './TagGraph';
import './TagDetails.scss';

export const TagDetails = React.memo(function TagDetails({ tagId }: { tagId: string }) {
    const { tags, setTags } = useTags();
    const goToHome = useGoTo('/');

    const deleteTag = useCallback(() => {
        if (window.confirm('Are you sure?')) {
            setTags(removeTagInTags(tagId));
            goToHome();
        }
    }, [setTags, tagId, goToHome]);

    const incrementTag = useCallback(() => {
        setTags(incrementTagInTags(tagId));
    }, [setTags, tagId]);

    const decrementTag = useCallback(() => {
        setTags(decrementTagInTags(tagId));
    }, [setTags, tagId]);

    const resetTag = useCallback(() => {
        setTags(resetHistoryInTags(tagId));
    }, [setTags, tagId]);

    const onChangeField = useCallback(
        (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const key = e.target.dataset.field!;
            const value = e.target.value;
            setTags(updateTag(tagId, key, value));
        },
        [setTags, tagId]
    );

    const tag = tags.find((t) => t.id === tagId);

    if (!tag) {
        return <div className="tag-details page">404 Not found</div>;
    }

    const textColor = getContrastYIQ(tag.color);
    return (
        <div className="tag-details page" style={{ color: textColor }}>
            <div className="details-header" style={{ backgroundColor: tag.color }}>
                <div className="details-counter value">{tag.count}</div>

                <input
                    className="details-field details-title"
                    type="text"
                    placeholder="Name"
                    onChange={onChangeField}
                    data-field={'title'}
                    value={tag.title}
                    onKeyPress={blurOnEnter}
                />
                <div className="details-actions">
                    <div
                        className="tooltip button"
                        data-tooltip="Remove 1"
                        data-tooltip-position="bottom"
                        onClick={decrementTag}
                    >
                        -
                    </div>
                    <div
                        className="tooltip button"
                        data-tooltip="Add 1"
                        data-tooltip-position="bottom"
                        onClick={incrementTag}
                    >
                        +
                    </div>
                    <div
                        className="tooltip button"
                        data-tooltip="Reset history"
                        data-tooltip-position="bottom"
                        onClick={resetTag}
                    >
                        <ResetIcon width="20" height="20" />
                    </div>
                    <div
                        className="tooltip button"
                        data-tooltip="Delete this tag"
                        data-tooltip-position="bottom"
                        onClick={deleteTag}
                    >
                        <DeleteIcon width="20" height="20" />
                    </div>
                </div>
            </div>

            <TagGraph history={tag.history} color={tag.color} setTags={setTags} tagId={tagId} />
        </div>
    );
});
