import classNames from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useBoolean } from '../hooks/useBoolean';
import { ConfigContext, useConfigState } from '../hooks/useConfig';
import { useGoTo } from '../hooks/useGoTo';
import { useTagsState } from '../hooks/useTags';
import './App.scss';
import { Header } from './Header/Header';
import { Menu } from './Menu/Menu';
import { TagContext } from './TagContext';
import { TagDetails } from './TagDetails/TagDetails';
import { TagList } from './TagList/TagList';

export const App = React.memo(function App() {
    const configState = useConfigState();

    const goToHome = useGoTo('/');
    const tagsState = useTagsState();
    const { tags, setTags } = tagsState;
    const { id: openedTag } = useParams<{ id?: string }>();

    const { value: isMenuOpened, setFalse: closeMenu, toggle: toggleMenuOpened } = useBoolean(false);

    const onDeleteAllTags = useCallback(() => {
        if (window.confirm('Are you sure?')) {
            setTags([]);
            goToHome();
        }
    }, [goToHome, setTags]);

    const history = useHistory();
    useEffect(() => {
        history.listen(() => {
            closeMenu();
        });
    }, [closeMenu, history]);

    console.log(configState);

    return (
        <ConfigContext.Provider value={configState}>
            <TagContext.Provider value={tagsState}>
                <div className={classNames('app', { nightMode: configState.config.nightMode })}>
                    <Header isTagOpened={!!openedTag} toggleMenuOpened={toggleMenuOpened} />

                    <Menu
                        hasTags={!!tags.length}
                        deleteTags={onDeleteAllTags}
                        close={closeMenu}
                        opened={isMenuOpened}
                    />

                    <div className="page-container">
                        {openedTag ? (
                            <TagDetails tagId={openedTag} />
                        ) : (
                            <div className="app-main">
                                <TagList />
                            </div>
                        )}
                    </div>
                </div>
            </TagContext.Provider>
        </ConfigContext.Provider>
    );
});
