import { RefObject, useLayoutEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export interface ResizeObserverEntry {
    target: HTMLElement;
    contentRect: DOMRectReadOnly;
}

export const useWidth = (ref: RefObject<HTMLElement>) => {
    const [width, setWidth] = useState<number>();

    useLayoutEffect(() => {
        if (!ref.current) {
            return;
        }
        const handleResize = (entries: ResizeObserverEntry[]) => {
            if (!Array.isArray(entries)) {
                return;
            }

            const entry = entries[0];
            setWidth(entry.contentRect.width);
        };

        let RO: any = new ResizeObserver((entries: ResizeObserverEntry[]) => handleResize(entries));
        RO.observe(ref.current);

        return () => {
            RO.disconnect();
            RO = null;
        };
    }, [ref]);

    return width;
};
