import classNames from 'classnames';
import React, { useCallback, useRef } from 'react';
import { ReactComponent as ChartIcon } from '../../assets/icons/chart.svg';
import { getContrastYIQ } from '../../colors.utils';
import { useGoTo } from '../../hooks/useGoTo';
import { useLongPress } from '../../hooks/useLongPress';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { useWidth } from '../../hooks/useWidth';
import { decrementTagInTags, incrementTagInTags } from '../../tag.utils';
import { Tag } from '../../types';
import { noop } from '../../utils';
import './TagItem.scss';
import { hasTouch } from '../../constants';

export const TagItem = React.memo(function TagItem({
    index,
    tag,
    setTags,
    isOpenedTagMenu,
    setOpenedTagMenu,
}: {
    index: number;
    tag: Tag;
    isOpenedTagMenu: boolean;
    setOpenedTagMenu: React.Dispatch<React.SetStateAction<string | null>>;
    setTags: React.Dispatch<React.SetStateAction<Tag[]>>;
}) {
    const tagId = tag.id;

    const goToTag = useGoTo(`/details/${tag.id}`);

    const closeTagMenu = useCallback(() => {
        setOpenedTagMenu(null);
    }, [setOpenedTagMenu]);

    const onLongClick = useCallback(() => {
        setOpenedTagMenu(tagId);
    }, [setOpenedTagMenu, tagId]);

    const incrementTag = useCallback(() => {
        setTags(incrementTagInTags(tagId));
    }, [setTags, tagId]);

    const decrementTag = useCallback(() => {
        setTags(decrementTagInTags(tagId));
    }, [setTags, tagId]);

    const tagRef = useRef<HTMLDivElement>(null);
    useOnClickOutside(tagRef, isOpenedTagMenu ? closeTagMenu : noop);

    const pressProps = useLongPress(isOpenedTagMenu ? noop : incrementTag, isOpenedTagMenu ? noop : onLongClick, 500);

    const textColor = getContrastYIQ(tag.color);
    const tagWidth = useWidth(tagRef);

    const onRightClick = useCallback(
        (e) => {
            e.preventDefault();
            setOpenedTagMenu(tagId);
        },
        [setOpenedTagMenu, tagId]
    );

    return (
        <div
            ref={tagRef}
            className={classNames('tag', { 'menu-opened': isOpenedTagMenu })}
            data-id={tag.id}
            style={{ backgroundColor: tag.color, height: tagWidth, '--appearDelay': index } as any}
            {...pressProps}
            onContextMenu={hasTouch ? noop : onRightClick}
        >
            <div className="tag-menu">
                <div className="tag-menu-item" onClick={decrementTag}>
                    <div className="tag-menu-icon">—</div>
                </div>
                <div className="tag-menu-item" onClick={incrementTag}>
                    <div className="tag-menu-icon">+</div>
                </div>
                <div className="tag-menu-item tag-menu-details" onClick={goToTag} data-id={tag.id}>
                    <div className="tag-menu-icon">
                        <ChartIcon width="40" height="40" />
                    </div>
                </div>
                <div className="tag-menu-item" onClick={closeTagMenu}>
                    <div className="tag-menu-icon menu-counter">{tag.count}</div>
                </div>
            </div>
            <div className="graph"></div>
            <span style={{ color: textColor }} className="value">
                {tag.count}
            </span>
            <span style={{ color: textColor }} className="title">
                {tag.title}
            </span>
        </div>
    );
});
