import React, { useCallback, useMemo, useState } from 'react';
import { ColorResult, HSLColor, HuePicker } from 'react-color';
import { getContrastYIQ, getRandomColor, hslToHex } from '../../colors.utils';
import { useOnClickOutsideRef } from '../../hooks/useOnClickOutside';
import { createTag } from '../../tag.utils';
import { Tag } from '../../types';
import { onValueChange } from '../../utils';
import { useTags } from '../TagContext';
import './TagAddForm.scss';

export const TagAddForm = React.memo(function TagAddForm({
    width,
    toggleTagAddForm,
}: {
    width?: number;
    toggleTagAddForm: () => void;
}) {
    const { setTags } = useTags();

    const [name, setName] = useState('');
    const [color, setColor] = useState<HSLColor>(getRandomColor);

    const onColorChange = useCallback((color: ColorResult) => {
        setColor(color.hsl);
    }, []);

    const hexColor = useMemo(() => hslToHex(color.h, color.s, color.l), [color]);
    const onSubmit = useCallback(() => {
        setTags((tags: Tag[]) => [...tags, createTag(name, hexColor)]);
        toggleTagAddForm();
    }, [setTags, toggleTagAddForm, name, hexColor]);
    const onKeyPress = useCallback(
        (e: React.KeyboardEvent) => {
            if (e.key === 'Enter') {
                onSubmit();
            }
        },
        [onSubmit]
    );

    const textColor = getContrastYIQ(hexColor);

    return (
        <div
            ref={useOnClickOutsideRef(toggleTagAddForm)}
            className="tag-menu tag-add-form-menu"
            style={{ backgroundColor: hexColor, color: textColor }}
        >
            <div className="tag-add-form-close" onClick={toggleTagAddForm}>
                ←
            </div>
            <div className="tag-add-form-submit" onClick={onSubmit}>
                ✓
            </div>
            <span className="key-counter value">1</span>

            <input
                className="tag-add-form-field title"
                type="text"
                value={name}
                placeholder={'Name...'}
                autoFocus={true}
                onChange={onValueChange(setName)}
                onKeyPress={onKeyPress}
            />
            <div className="tag-add-form-color" style={{ width }}>
                <HuePicker width={'100%'} color={color} onChange={onColorChange} />
            </div>
        </div>
    );
});
