/**
 * ******************************************************************************
 * Copyright Innov'ATM all rights reserved. This software is the property of
 * Innov'ATM and may not be used in any manner except under a license agreement
 * signed with Innov'ATM.
 * *******************************************************************************
 */

import React, { useContext } from 'react';
import { Tag } from '../types';

export interface TagContextValue {
    tags: Tag[];
    setTags: React.Dispatch<React.SetStateAction<Tag[]>>;
}
export const TagContext = React.createContext<TagContextValue>(null as any);

export const useTags = () => {
    return useContext(TagContext);
}
