import React, { useRef } from 'react';
import { useWidth } from '../../hooks/useWidth';
import { TagAddForm } from '../TagAddForm/TagAddForm';
import '../TagAddForm/TagAddForm.scss';
import classNames from 'classnames';
import { noop } from '../../utils';
import { useBoolean } from '../../hooks/useBoolean';

export const TagItemAdd = React.memo(function TagItemAdd() {
    const tagRef = useRef<HTMLDivElement>(null);
    const tagWidth = useWidth(tagRef);

    const { value: isAddFormOpened, toggle: toggleTagAddForm } = useBoolean(false);

    return (
        <div
            ref={tagRef}
            className={classNames('tag tag-add-btn', { 'menu-opened': isAddFormOpened })}
            style={{ height: tagWidth }}
            onClick={isAddFormOpened ? noop : toggleTagAddForm}
        >
            {isAddFormOpened && <TagAddForm width={tagWidth} toggleTagAddForm={toggleTagAddForm} />}
            <span className="key-counter value">+</span>
            <p className="title">New tag</p>
        </div>
    );
});
