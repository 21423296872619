import { useRef, useCallback } from 'react';

export const useLongPress = (simplePress: () => void, longPress: () => void, timeout = 300) => {
    const timeoutRef = useRef<any>();

    const onPointerDown = useCallback(() => {
        timeoutRef.current = setTimeout(() => {
            clearTimeout(timeoutRef.current);
            longPress();
        }, timeout);
    }, [timeoutRef, longPress, timeout]);
    const onPointerUp = useCallback(
        (e) => {
            if (e.button === 2) {
                return;
            }
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
                simplePress();
            }
        },
        [timeoutRef, simplePress]
    );

    return { onPointerDown, onPointerUp };
};
