import { Config } from "./types";


export const appVersion = 1;

export const isProduction = process.env.NODE_ENV === 'production';

export const hasTouch = 'ontouchstart' in window;


export const defaultConfig: Config = {
    autoSave: true,
    nightMode: false,
};
