import React from "react";

import './ToggleButton.scss';
import classNames from "classnames";

export const ToggleButton = React.memo(({value, onChange}: {value: boolean; onChange: () => void}) => {
  return (
    <div className={classNames('toggle', {'toggle-on': value})}>
      <div className="toggle-text-off">OFF</div>
      <div className="glow-comp"></div>
      <div className="toggle-button"></div>
      <div className="toggle-text-on">ON</div>
    </div>
  );
});
