import { Tag } from './types';
import { v4 } from 'uuid';

export interface OldData {
    tag: {
        title: string;
        desc: string;
        count: number;
        color: string;
        history: { date: number }[];
        id: number;
    }[];
}

export const convertData = (old: OldData): Tag[] => {
    return old.tag.map(
        ({ title, count, color, history }): Tag => {
            return {
                id: v4(),
                title,
                count,
                color,
                history: history.map(({ date }) => ({ date, value: 1 })),
            };
        }
    );
};

export const migrateStorage = (setTags: (tags: Tag[]) => void) => {
    const oldData = localStorage.getItem('key.data');
    localStorage.removeItem('key.data');
    if (oldData && oldData.length) {
        return setTags(convertData(JSON.parse(oldData)));
    }
};
