import { ChangeEvent } from 'react';

export const onValueChange = (cb: (v: any) => void) => (e: ChangeEvent<any>) => cb(e.target.value);

export const noop = () => {};

export const blurOnEnter = (e) => (e.key === 'Enter' ? e.target.blur() : null);

export const preventDefault = (e) => e.preventDefault();

export function composedPath(event: any) {
    const nativePath = event.path || (event.composedPath && event.composedPath());
    if (nativePath) {
        return nativePath;
    }

    const path: any[] = [];
    let element = event.target;

    while (element) {
        path.push(element);

        if (element.tagName === 'HTML') {
            return path;
        }

        element = element.parentElement;
    }

    return [];
}
