import React from 'react';
import { Link } from 'react-router-dom';
import './Header.scss';

export const Header = React.memo(function Header({
    isTagOpened,
    toggleMenuOpened,
}: {
    isTagOpened: boolean;
    toggleMenuOpened: () => void;
}) {
    return (
        <header>
            <Link to={'/'} className="logo">
                {isTagOpened && <span className="app-back">&lt; </span>}
                ThatMuch
            </Link>
            <div className="app-menu-button" onClick={toggleMenuOpened}>
                <span className="menuicon">
                    &nbsp;
                </span>
            </div>
        </header>
    );
});
