import { useState, useEffect, useContext, useMemo } from 'react';
import { defaultConfig } from '../constants';
import { Config } from '../types';
import React from 'react';

export const useConfigState = () => {
    const [config, setConfig] = useState(() =>
        JSON.parse(localStorage.getItem('config') || JSON.stringify(defaultConfig))
    );

    useEffect(() => {
        localStorage.setItem('config', JSON.stringify(config));
    }, [config]);

    const value = useMemo(() => ({ config, setConfig }), [config, setConfig]);
    
    return value;
};

export interface ConfigContextValue {
    config: Config;
    setConfig: React.Dispatch<React.SetStateAction<Config>>;
}
export const ConfigContext = React.createContext<ConfigContextValue>(null as any);

export const useConfig = () => {
    return useContext(ConfigContext);
};
