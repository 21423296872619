import React, { useCallback, useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { hasTouch } from '../../constants';
import { useConfig } from '../../hooks/useConfig';
import { undoHistoryIndexInTags } from '../../tag.utils';
import { Tag } from '../../types';
import { composedPath, preventDefault } from '../../utils';
import './TagGraph.scss';

export const TagGraph = React.memo(function TagGraph({
    tagId,
    setTags,
    history,
    color,
}: {
    tagId: string;
    setTags: React.Dispatch<React.SetStateAction<Tag[]>>;
    history: { date: number; value: number }[];
    color: string;
}) {
    const { config } = useConfig();
    const dates = useMemo(() => {
        return history.reduce((acc, cur) => {
            acc.push([cur.date, (acc[acc.length - 1]?.[1] ?? 0) + cur.value]);
            return acc;
        }, [] as [number, number][]);
    }, [history]);

    const series = useMemo(() => {
        return [
            {
                name: '',
                data: dates,
            },
        ];
    }, [dates]);

    const count = history.length;
    const options = useMemo(
        () => ({
            colors: [color, '#222'],
            chart: {
                theme: {
                    mode: config.nightMode ? 'dark' : 'light',
                },
                type: 'area',
                stacked: false,
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true,
                },
                toolbar: {
                    autoSelected: 'zoom',
                    show: true,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                        download: false,
                        selection: false,
                        zoom: !hasTouch,
                        zoomin: true,
                        zoomout: true,
                        pan: !hasTouch,
                        reset: true,
                        customIcons: [],
                    },
                },
                events: {
                    markerClick: (e: MouseEvent, w, { seriesIndex, dataPointIndex }) => {
                        e.preventDefault();
                        if (e.button === 2) {
                            setTags(undoHistoryIndexInTags(tagId, dataPointIndex));
                        }
                    },
                },
            },
            dropShadow: {
                enabled: true,
                top: 0,
                left: 0,
                blur: 3,
                opacity: 0.5,
            },
            dataLabels: {
                enabled: true,
                style: {
                    colors: ['#777'],
                },
            },
            stroke: {
                curve: 'stepline',
            },
            markers: {
                size: 1,
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    inverseColors: false,
                    opacityFrom: 0.5,
                    opacityTo: 0,
                    stops: [0, 90, 100],
                },
            },
            yaxis: {
                labels: {
                    formatter: function (val: number) {
                        return Math.round(val);
                    },
                    color: 'red',
                },
                max: count + 1,
                tickAmount: count + 1,
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    datetimeUTC: true,
                    datetimeFormatter: {
                        year: 'yyyy',
                        month: "MMM 'yy",
                        day: 'dd MMM',
                        hour: 'HH:mm',
                        minute: 'HH:mm:ss',
                    },
                },
            },
            tooltip: {
                enabled: true,
                y: {
                    show: false,
                    title: '',
                },
                x: {
                    show: false,
                    format: 'dd MMM HH:mm:ss',
                },
                marker: {
                    show: false,
                },
                custom: function ({ series, seriesIndex, dataPointIndex }) {
                    return `<div class="graph-tooltip" data-index="${dataPointIndex}">
                        ${hasTouch ? '× Remove event' : 'Right-click to remove event'}
                    </div>`;
                },
            },
        }),
        [color, config.nightMode, count, setTags, tagId]
    );

    const onGraphClick = useCallback(
        (e) => {
            const tooltipEl = composedPath(e).find((el) => el.classList && el.classList.contains('graph-tooltip'));
            if (tooltipEl) {
                const historyIndex = +tooltipEl.dataset.index;
                setTags(undoHistoryIndexInTags(tagId, historyIndex));
            }
        },
        [setTags, tagId]
    );

    return (
        <div className="graph" onContextMenu={preventDefault} onClick={onGraphClick}>
            <ReactApexChart options={options} series={series} type="area" height={300} />
        </div>
    );
});
