import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useConfig } from '../../hooks/useConfig';
import { ToggleButton } from '../ToggleButton';
import './Menu.scss';

export const Menu = React.memo(function Menu({
    hasTags,
    deleteTags,
    opened,
    close,
}: {
    opened: boolean;
    close: () => void;
    hasTags: boolean;
    deleteTags: () => void;
}) {
    const { config, setConfig } = useConfig();
    const onToggleAutoSave = useCallback(() => {
        setConfig((c) => ({ ...c, autoSave: !c.autoSave }));
    }, [setConfig]);
    const onToggleNightMode = useCallback(() => {
        setConfig((c) => ({ ...c, nightMode: !c.nightMode }));
    }, [setConfig]);

    return (
        <nav className={classNames('app-menu', { open: opened })}>
            <ul className="menulist">
                <li className="menuitem" onClick={onToggleAutoSave}>
                    Auto save <ToggleButton onChange={onToggleAutoSave} value={config.autoSave} />
                </li>
                <li className="menuitem" onClick={onToggleNightMode}>
                    Night mode <ToggleButton onChange={onToggleNightMode} value={config.nightMode} />
                </li>
                <li
                    className={classNames('menuitem', {
                        disabled: !hasTags,
                    })}
                    onClick={deleteTags}
                >
                    Delete all tags
                </li>
            </ul>
        </nav>
    );
});
