import { useEffect, useState, useMemo } from 'react';
import { migrateStorage } from '../migration';
import { Tag } from '../types';

export const useTagsState = () => {
    const [tags, setTags] = useState<Tag[]>(() => JSON.parse(localStorage.getItem('tags') || '[]'));

    useEffect(() => {
        migrateStorage(setTags);
    }, [setTags]);

    useEffect(() => {
        localStorage.setItem('tags', JSON.stringify(tags));
    }, [tags]);
    
    const value = useMemo(() => ({ tags, setTags }), [tags, setTags]);

    return value;
};
