import React, { useState } from 'react';
import { useTags } from '../TagContext';
import { TagItem } from '../TagItem/TagItem';
import { TagItemAdd } from '../TagItem/TagItemAdd';
import './TagList.scss';

export const TagList = React.memo(function TagList() {
    const { tags, setTags } = useTags();
    const [openedTagMenu, setOpenedTagMenu] = useState<string | null>(null);

    return (
        <div className="tags">
            <TagItemAdd />

            {tags.slice().reverse().map((tag, i) => (
                <TagItem
                    key={tag.id}
                    tag={tag}
                    setTags={setTags}
                    isOpenedTagMenu={openedTagMenu === tag.id}
                    setOpenedTagMenu={setOpenedTagMenu}
                    index={i / tags.length}
                />
            ))}
        </div>
    );
});
