/*
 * ******************************************************************************
 * Copyright Innov'ATM all rights reserved. This software is the property of
 * Innov'ATM and may not be used in any manner except under a license agreement
 * signed with Innov'ATM.
 * *******************************************************************************
 */






import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { App } from "./App";

export const Root = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={App} exact />
        <Route path="/home" component={App} exact />
        <Route path="/details/:id" component={App} exact />
      </Switch>
    </BrowserRouter>
  );
};
