import React from "react";
import ReactDOM from "react-dom";
import { Root } from "./components/Root";
import * as serviceWorker from "./serviceWorker";
import { isProduction } from "./constants";

import "./fonts.scss";
import "./index.scss";

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById("root")
);

if (isProduction) {
  serviceWorker.register();
} else {
  serviceWorker.unregister();
}
