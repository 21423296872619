import { v4 } from 'uuid';
import { Tag } from './types';

export const createTag = (title: string, color: string): Tag => {
    return {
        id: v4(),
        title,
        color,
        history: [{ value: 1, date: Date.now() }],
        count: 1,
    };
};

export const incrementTagInTags = (tagId: string) => (tags: Tag[]): Tag[] =>
    tags.map((t) =>
        t.id === tagId ? { ...t, count: t.count + 1, history: [...t.history, { value: 1, date: Date.now() }] } : t
    );

export const decrementTagInTags = (tagId: string) => (tags: Tag[]): Tag[] =>
    tags.map((t) =>
        t.id === tagId ? { ...t, count: t.count - 1, history: [...t.history, { value: -1, date: Date.now() }] } : t
    );
export const undoHistoryInTags = (tagId: string) => (tags: Tag[]): Tag[] =>
    tags.map((t) =>
        t.id === tagId && t.history.length > 0
            ? { ...t, count: t.count - t.history[t.history.length - 1].value, history: t.history.slice(0, -1) }
            : t
    );

export const resetHistoryInTags = (tagId: string) => (tags: Tag[]): Tag[] =>
    tags.map((t) => (t.id === tagId ? { ...t, count: 0, history: [{ value: 0, date: Date.now() }] } : t));

export const undoHistoryIndexInTags = (tagId: string, historyIndex: number) => (tags: Tag[]): Tag[] =>
    tags.map((t) =>
        t.id === tagId
            ? {
                  ...t,
                  count: t.count - t.history[historyIndex].value,
                  history: [...t.history.slice(0, historyIndex), ...t.history.slice(historyIndex + 1)],
              }
            : t
    );

export const removeTagInTags = (tagId: string) => (tags: Tag[]) => tags.filter((t) => t.id !== tagId);

export const updateTag = (tagId: string, key: string, value: any) => (tags: Tag[]) => {
    return tags.map((t) => (t.id === tagId ? { ...t, [key]: value } : t));
};
