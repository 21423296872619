import { useState, useCallback } from "react";

export const useBoolean = (initial: boolean) => {
    const [value, setValue] = useState(initial);
    return{
      value,
      setValue,
      toggle: useCallback(() => setValue((value: any) => !value), []),
      setTrue: useCallback(() => setValue(true), []),
      setFalse: useCallback(() => setValue(false), []),
    }
  }